import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card, Text } from 'theme-ui'

const PageCookiePolicy = props => {

  return (
    <Layout {...props}>
      <Seo title='Çerez Politikası' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Çerez Politikası'
          subheader='Bu sayfa, "BlogBahane" için çerez politikasıdır ve "www.blogbahane.com" adresinden erişilebilir.'
        />
      </Stack>
      <Stack>
        <Main>

          <Text variant='h4'>
            Çerezler Nedir?
          </Text>
          <Text variant='p'>
            https://www.blogbahane.com/iletisim
          </Text>

          <Text variant='h4'>
            Çerezleri Nasıl Kullanıyoruz?
          </Text>
          <Text variant='p'>
            Çerezleri aşağıda açıklanan çeşitli nedenlerle kullanırız. Ne yazık ki çoğu zaman çerezleri devre dışı bırakırken web sitesine kattıkları
            fonksiyonellik ve özellikleri de engellemiş olursunuz ve bunun önlenmesi için de bir endüstri standardı yoktur. İhtiyacınız olup olmadığından
            emin değilseniz, kullandığınız bir hizmeti sağlamak için çerez ayarlarını değiştirmemeniz önerilir. Çerezler ile siteye sonraki erişimlerinizde
            tercihlerinizin hatırlanmasına ve sitedeki deneyiminizi iyileştirmek için hizmetlerimizde geliştirmeler yapmamıza yardımcı olur. Böylece daha
            iyi ve kişiselleştirilmiş bir kullanım deneyimi yaşarsınız.
          </Text>

          <Text variant='h4'>
            Çerezleri Devre Dışı Bırakma
          </Text>
          <Text variant='p'>
            Tarayıcınızdaki ayarları düzenleyerek çerezlerin ayarını değiştirebilirsiniz (bunun nasıl yapılacağı için tarayıcınızın Yardımına bakın).
            Çerezleri devre dışı bırakmanın bunun ve ziyaret ettiğiniz diğer birçok web sitesinin işlevselliğini etkileyeceğini unutmayın. Çerezleri devre
            dışı bırakmak genellikle bu sitenin belirli işlevlerini ve özelliklerini de devre dışı bırakmanıza neden olur. Bu nedenle çerezleri devre dışı
            bırakmamanız önerilir.
          </Text>

          <Text variant='h4'>
            Kullandığımız Çerezler
          </Text>
          <Text as='ul'>
            <li>
              Oturum Çerezleri: Oturum çerezleri ziyaretçilerimizin Site’yi ziyaretleri süresince kullanılan, tarayıcı kapatıldıktan sonra silinen geçici
              çerezlerdir. Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz süresince Site’nin düzgün bir biçimde çalışmasının teminini sağlamaktır.
              Örneğin; birden fazla sayfadan oluşan çevrimiçi formları doldurmanız sağlanmaktadır.
            </li>
            <li>
              Kalıcı Çerezler: Kalıcı çerezler Site’nin işlevselliğini artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak amacıyla kullanılan
              çerez türleridir. Bu tür çerezler tercihlerinizi hatırlamak için kullanılır ve tarayıcılar vasıtasıyla cihazınızda depolanır. Kalıcı çerezlerin
              bazı türleri; Site’yi kullanım amacınız gibi hususlar göz önünde bulundurarak sizlere özel öneriler sunulması için kullanılabilmektedir.
              Kalıcı çerezler sayesinde Site’yi aynı cihazla tekrardan ziyaret etmeniz durumunda, cihazınızda Site tarafından oluşturulmuş bir çerez olup
              olmadığı kontrol edilir ve var ise, sizin Site’yi daha önce ziyaret ettiğiniz anlaşılır ve size iletilecek içerik bu doğrultuda belirlenir ve
              böylelikle sizlere daha iyi bir hizmet sunulur.
            </li>
          </Text>

          <Text variant='h4'>
            Üçüncü Parti Çerezleri
          </Text>
          <Text variant='p'>
            Bazı özel durumlarda, güvenilir üçüncü taraflarca sağlanan çerezleri de kullanırız. Aşağıdaki bölüm, bu site üzerinden hangi üçüncü taraf
            çerezleriyle karşılaşabileceğinizi göstermektedir.
          </Text>
          <Text as='ul'>
            <li>
              Bu site, siteyi nasıl kullandığınızı ve deneyiminizi nasıl geliştirebileceğimizi anlamamıza yardımcı olmak için web’deki en yaygın ve güvenilir
              analitik çözümlerinden biri olan Google Analytics’i kullanıyor. Bu çerezler, sitede ne kadar zaman harcadığınız ve site içinde açtığınız
              sayfalar gibi verileri izleyebilir, böylece ilgi çekici içerikler üretmeye devam edebiliriz.
            </li>
            <li>
              Ayrıca bu siteye çeşitli sosyal medya hesaplarınızla kayıt/giriş yapabilmeniz için sosyal medya butonları ve pluginleri kullanıyoruz. Bunların
              çalışması için sosyal medya siteleri; (Google, Facebook), sitelerinde profilinizi geliştirmek veya kendi gizlilik politikalarında belirtilen
              çeşitli amaçlar için tuttukları verilere katkıda bulunmak için sitemiz üzerinden çerezler koyabilirler.
            </li>
          </Text>

          <Text variant='h4'>
            Daha Fazla Bilgi
          </Text>
          <Text variant='p'>
            Çerez Politikası ile ilgili tüm soru ve görüşlerinizi iletmek için bize ulaşabilirsiniz.
          </Text>
          <Text variant='p'>
            https://www.blogbahane.com/iletisim
          </Text>

        </Main>
      </Stack>
    </Layout>
  )
}

export default PageCookiePolicy
